<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Wallet Configuration: {{ form.code }}</span>
                <span class="headline" v-else>Create Wallet Configuration</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm8 v-if="formType === 'UPDATE'">
                                <v-text-field
                                    label="ID"
                                    hide-details="auto"
                                    data-cy="form-id"
                                    v-model="form.id"
                                    readonly
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8 v-if="formType === 'UPDATE'">
                                <v-text-field
                                    label="Created By"
                                    hide-details="auto"
                                    data-cy="form-createdby"
                                    v-model="form.createdBy"
                                    readonly
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8 v-if="formType === 'UPDATE'">
                                <v-text-field
                                    label="Date Created"
                                    hide-details="auto"
                                    data-cy="form-dateCreated"
                                    v-model="form.dateCreated"
                                    readonly
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8 v-if="formType === 'CREATED'">
                                <v-text-field
                                    label="Code*"
                                    hide-details="auto"
                                    data-cy="form-code"
                                    v-model="form.code"
                                    :readonly="shouldBeReadOnly"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Display Name*"
                                    hide-details="auto"
                                    data-cy="form-display-name"
                                    v-model="form.displayName"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8 v-if="formType === 'CREATED'">
                                <v-text-field
                                    label="Base URL*"
                                    hide-details="auto"
                                    data-cy="form-base-url"
                                    v-model="form.baseUrl"
                                    :readonly="shouldBeReadOnly"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8 v-if="formType === 'CREATED'">
                                <v-text-field
                                    label="Transaction Endpoint*"
                                    hide-details="auto"
                                    data-cy="form-transaction-endpoint"
                                    v-model="form.transactionEndpoint"
                                    :readonly="shouldBeReadOnly"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8 v-if="formType === 'CREATED'">
                                <v-text-field
                                    label="Accounts Endpoint*"
                                    hide-details="auto"
                                    data-cy="form-accounts-endpoint"
                                    v-model="form.accountsEndpoint"
                                    :readonly="shouldBeReadOnly"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8 v-if="formType === 'CREATED'">
                                <v-text-field
                                    label="Balance Endpoint*"
                                    hide-details="auto"
                                    data-cy="form-balance-endpoint"
                                    v-model="form.balanceEndpoint"
                                    :readonly="shouldBeReadOnly"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Debit Endpoint*"
                                    hide-details="auto"
                                    data-cy="form-debit-endpoint"
                                    v-model="form.debitEndpoint"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Refund Endpoint*"
                                    hide-details="auto"
                                    data-cy="form-refund-endpoint"
                                    v-model="form.refundEndpoint"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
    },
    computed: {
        populatedForm() {
            return {
                code: this.form.code,
                displayName: this.form.displayName,
                baseUrl: this.form.baseUrl,
                transactionEndpoint: this.form.transactionEndpoint,
                accountsEndpoint: this.form.accountsEndpoint,
                balanceEndpoint: this.form.balanceEndpoint,
                debitEndpoint: this.form.debitEndpoint,
                refundEndpoint: this.form.refundEndpoint,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            shouldBeReadOnly: false,
            form: {
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                properEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid. Ex. juandelacruz@apollo.com.ph'
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-wallet-params', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    code: undefined,
                    displayName: undefined,
                    baseUrl: undefined,
                    transactionEndpoint: undefined,
                    accountsEndpoint: undefined,
                    balanceEndpoint: undefined,
                    debitEndpoint: undefined,
                    refundEndpoint: undefined,
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.shouldBeReadOnly = true
                this.form = {
                    id: this.selectedItem.id,
                    createdBy: this.selectedItem.createdBy,
                    dateCreated: this.selectedItem.dateCreated,
                    code: this.selectedItem.code,
                    displayName: this.selectedItem.displayName,
                    baseUrl: this.selectedItem.baseUrl,
                    transactionEndpoint: this.selectedItem.transactionEndpoint,
                    accountsEndpoint: this.selectedItem.accountsEndpoint,
                    balanceEndpoint: this.selectedItem.balanceEndpoint,
                    debitEndpoint: this.selectedItem.debitEndpoint,
                    refundEndpoint: this.selectedItem.refundEndpoint,
                }
            }
        },
    }
}
</script>
