<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" data-cy="form-details"/>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Wallet Tenant Configuration</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-1">
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Code" single-line @change="searchCode" data-cy="search-display-name"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="ma-2">
            <v-col class="d-flex" cols="12" md="1">
                <v-btn dark medium color=success @click="queryData(true)" class="mt-2" data-cy="action-button-search">
                    <v-icon dark left> mdi-magnify</v-icon>Search
                </v-btn>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-flex xs12 sm3>
                    <v-btn :block="$vuetify.breakpoint.mdAndDown" class="ml-10 mt-2" color="success" @click.stop="showWalletCredentialParams('CREATED')" data-cy="action-button-create-wallet">Create Wallet Configuration</v-btn>
                    <wallet-credentials-form :selected-item="selectedWalletCredentials" :is-show="showFormDialog" :form-type="walletCredentialsParamsForm" v-on:close-dialog="closeDialog" v-on:save-wallet-params="saveWalletCredParams" data-cy="form-wallet-credentials"/>
                </v-flex>
            </v-col>
        </v-row>
    </v-card>

    <!-- <div class="text-uppercase text-bold">id selected: {{selected}}</div> -->
    <!-- <div class="col-md-6 text-right">
        <strong>Total Amount:</strong> <span class="price-content">{{totalAmount}}</span>
    </div> -->

    <template>
        <v-card>
            <v-data-table :hide-default-header="true" :headers="headers" :items="displayMatchedTransactions" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMatchedTransactions" :footer-props="{'items-per-page-options': [10]}">
                <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr :colspan="headers.length">
                                <th v-for="header in headers" :key="header.text">
                                    <span class="d-flex justify-center">{{header.text}}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.code }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.displayName }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.createdBy }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.updatedBy }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.lastUpdated }} </span></td>
                                <td>
                                    <span class="d-flex justify-center">
                                        <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTransaction(item)" data-cy="list-action-button-search">
                                            <v-icon dark medium>mdi-magnify-plus</v-icon>
                                        </v-btn>
                                        <v-btn fab dark x-small color="green" @click.stop="showWalletCredentialParams('UPDATE', item)" data-cy="list-action-button-update">
                                            <v-icon dark medium>mdi-pencil</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                            Found no results.
                        </v-alert>
                    </template>
            </v-data-table>           
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import WalletCredentialsForm from '@/views/components/walletCredentialsForm.vue'

export default {
    name: 'WalletCredentials',
    components: {
        Loading,
        DetailsForm,
        WalletCredentialsForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
    },
    data() {
        return {
            headers: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Code',
                    value: 'code'
                },
                {
                    text: 'Name',
                    value: 'displayName'
                },
                {
                    text: 'Created By',
                    value: 'createdBy'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Updated By',
                    value: 'updatedBy'
                },
                {
                    text: 'Last Updated',
                    value: 'lastUpdated'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            walletCredentialsParamsForm: 'CREATED',
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMatchedTransactions: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            offset: 0,
            limit: 0,
            displayMatchedTransactions: [],
            remainderMatchedTransactions: [],
            paymentAgents: [],
            selectedWalletCredentials: {},
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            totalCount:'',
        }
    },
    watch: {
        options: {
            handler() {
                this.queryData(false);
            },
        },
    },
    methods: {
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var matchedTransactionList = undefined
                
                const parameters = {
                    code: this.filteredTransaction ? this.filteredTransaction.code ?? '' : '',
                    displayName: this.filteredTransaction ? this.filteredTransaction.displayName ?? '' : '',
                    pageNo: pageNumber,
                    pageSize: itemsPerPage
                }

                matchedTransactionList = await API.getWalletCredentialList(parameters)
                if (!matchedTransactionList || matchedTransactionList.error) {
                    console.log(`${matchedTransactionList.error}`)
                } else {
                    var filteredList = matchedTransactionList.walletTenant
                    if (page > 1 && this.remainderMatchedTransactions.length > 0) {
                        filteredList = this.remainderMatchedTransactions.concat(filteredList)
                    }
                    this.totalMatchedTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMatchedTransactions = filteredList.slice(0, end)
                    this.remainderMatchedTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMatchedTransactions / itemsPerPage

                    parameters.offset = this.totalMatchedTransactions
                    parameters.limit = 1
                    
                    const hasMoreResponse = await API.getWalletCredentialList(parameters)
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.walletTenant
                        if(hasMoreList.length > 0) {
                        this.totalMatchedTransactions = Math.floor(this.totalMatchedTransactions/10) * 10 + 1
                        this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        showWalletCredentialParams(what, item = {}) {
            this.walletCredentialsParamsForm = what
            this.selectedWalletCredentials = item
            this.showFormDialog = true
        },
        searchCode: async function(val) {
            this.filteredTransaction.code = val
            // this.queryData(true)
        },
        searchMerchantId(val) {
            this.filteredTransaction.merchantId = val
            // this.queryData(true)
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
                this.$router.go(0);
            }
        },
        async saveWalletCredParams(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new wallet credentials.`
                    setTimeout(async () => {
                        const createResponse = await API.createWalletCredentials(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                            this.showFormDialog = false
                        } else {
                            this.loadingMessage = `Successfully new wallet credentials.`
                            this.showFormDialog = false
                            // this.$refs.merchantParametersForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating wallet credentials.`
                    setTimeout(async () => {
                        const updateResponse = await API.updateWalletCredentials(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                            this.showFormDialog = false
                        } else {
                            this.loadingMessage = `Successfully updated wallet credentials.`
                            this.showFormDialog = false
                            // this.$refs.merchantParametersForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.title = transaction.sevenPayID
            this.details.merchantId = transaction.merchant
            this.details.status = transaction.paymentStatus
            this.details.item = transaction
            this.isShowDetails = true
        },
    }
};
</script>
